"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartServices = void 0;
var ChartServices;
(function (ChartServices) {
    ChartServices["ALL"] = "allServices";
    ChartServices["TEMPERATURE"] = "temperatureServices";
    ChartServices["HUMIDITY"] = "humidityServices";
    ChartServices["PRESSURE"] = "pressureServices";
})(ChartServices = exports.ChartServices || (exports.ChartServices = {}));
