var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "loggersCalibrationList" } },
    [
      _vm.isLoading
        ? _c(
            "b-loading",
            {
              staticClass: "is-flex-direction-column",
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            },
            [
              _c("p", { staticClass: "pb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("component.location.detail.msg.file_download_info")
                    ) +
                    " "
                ),
              ]),
              _c("b-icon", {
                attrs: {
                  pack: "fas",
                  icon: "spinner",
                  size: "is-large",
                  "custom-class": "fa-pulse",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.loggers.calibrationExpiresList.title")
                ) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "level-item is-hidden-mobile",
                  attrs: { type: "is-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFilter()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generals.clearFilter")))]
              ),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        position: "is-bottom-left",
                        "aria-role": "list",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "admin.component.company.list.actions.columns"
                                    ),
                                    position: "is-left",
                                    type: "is-dark",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "is-dark-grey",
                                      staticStyle: { "font-size": "1rem" },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          type: "is-primary",
                                          icon: "list",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.tableColumns, function (column) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: column.field,
                          staticClass: "custom-dropdown-item",
                          attrs: {
                            focusable: false,
                            custom: "",
                            "aria-role": "listitem",
                          },
                        },
                        [
                          _c(
                            "b-checkbox",
                            {
                              attrs: {
                                disabled:
                                  column.field ==
                                  _vm.tableColumns.serialNumber.field,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.chooseTableColumns(
                                    column.field,
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: column.visible,
                                callback: function ($$v) {
                                  _vm.$set(column, "visible", $$v)
                                },
                                expression: "column.visible",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.loggers.loggerList.table." +
                                        column.field
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "is-flex pr-2 pb-2" },
        [
          _c("p", { staticClass: "pr-2 has-text-grey is-size-6" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "admin.component.loggers.calibrationExpiresList.calibrationExpire"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "b-field",
            [
              _c(
                "b-select",
                {
                  attrs: {
                    placeholder: _vm.$t(
                      "admin.component.loggers.calibrationExpiresList.placeholder"
                    ),
                    size: "is-small",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.changeCalibrationExpire($event)
                    },
                  },
                  model: {
                    value: _vm.selectedCalibrationExpire,
                    callback: function ($$v) {
                      _vm.selectedCalibrationExpire = $$v
                    },
                    expression: "selectedCalibrationExpire",
                  },
                },
                [
                  _vm._l(_vm.CalibrationExpires, function (expire) {
                    return _c(
                      "option",
                      { key: expire, domProps: { value: expire } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "admin.component.loggers.calibrationExpiresList.selectCalibrationExpire." +
                                  expire
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  _vm.customExpireDate
                    ? _c(
                        "option",
                        {
                          key: "customExpireDate",
                          attrs: { value: "customExpireDate", disabled: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.customExpireDate.toDateString()) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-table",
        {
          ref: "table",
          staticClass: "mobile-filter res-table",
          attrs: {
            data: _vm.loggerList,
            paginated: "",
            "per-page": _vm.pageSize,
            total: _vm.totalSize,
            "current-page": _vm.page,
            striped: "",
            "backend-sorting": "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
            "page-input": true,
            "pagination-order": "is-centered",
            "backend-filtering": "",
            "backend-pagination": "",
            "row-class": function (row) {
              return row.state.toLowerCase() ==
                _vm.LoggerStates.DEACTIVATED.toLowerCase()
                ? "has-background-grey-lighter "
                : ""
            },
          },
          on: {
            "page-change": _vm.onPageChange,
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
            sort: _vm.onSort,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bottom-left",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "component.notifications_list.table.row_per_page"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "b-field",
                      { staticClass: "pl-2 mb-4-mobile" },
                      [
                        _c(
                          "b-select",
                          {
                            on: { input: _vm.rowsPerPageSelected },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          },
                          _vm._l(_vm.PER_PAGE_OPTIONS, function (option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.loggerList && _vm.loggerList.length > 0
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "is-flex is-justify-content-flex-end",
                          },
                          [
                            _c("p", { staticClass: "pt-2 has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.company.list.table.results",
                                      {
                                        from: _vm.fromTableData,
                                        to: _vm.toTableData,
                                        total: _vm.totalSize,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.loggerId.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.loggerId.field
              ),
              visible: _vm.tableColumns.loggerId.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _c(
                      "span",
                      { staticClass: "is-family-monospace text-no-wrap" },
                      [_vm._v(_vm._s(loggerList.row.id))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              sortable: "",
              field: _vm.tableColumns.macAddress.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.macAddress.field
              ),
              visible: _vm.tableColumns.macAddress.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isMacAddressSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterMacAddressDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterLoggers(
                                        _vm.tableColumns.macAddress.field,
                                        _vm.macAddressSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.macAddressSearch,
                                    callback: function ($$v) {
                                      _vm.macAddressSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "macAddressSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterMacAddressDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.filterLoggers(
                                _vm.tableColumns.macAddress.field,
                                _vm.macAddressSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.macAddressSearch,
                            callback: function ($$v) {
                              _vm.macAddressSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "macAddressSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "adminLoggerDetail",
                            params: {
                              loggerId: loggerList.row.id,

                              lang: _vm.$route.params.lang,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(loggerList.row.macAddress))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.serialNumber.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.serialNumber.field
              ),
              visible: _vm.tableColumns.serialNumber.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isSerialNoSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "admin.component.loggers.loggerList.table.serialNumberSearch"
                                    ),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterSerialNumberDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterLoggers(
                                        _vm.tableColumns.serialNumber.field,
                                        _vm.serialNumberSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.serialNumberSearch,
                                    callback: function ($$v) {
                                      _vm.serialNumberSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "serialNumberSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "admin.component.loggers.loggerList.table.serialNumberSearch"
                            ),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterSerialNumberDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.filterLoggers(
                                _vm.tableColumns.serialNumber.field,
                                _vm.serialNumberSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.serialNumberSearch,
                            callback: function ($$v) {
                              _vm.serialNumberSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "serialNumberSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.currentlyPublishedAs
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminLoggerDetail",
                                params: {
                                  loggerId: loggerList.row.id,

                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                loggerList.row.currentlyPublishedAs.serialNumber
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    loggerList.row.currentlyPublishedAs &&
                    loggerList.row.currentlyPublishedAs.state ==
                      _vm.ServiceState.ERROR
                      ? _c(
                          "b-tag",
                          {
                            staticClass: "ml-1",
                            attrs: { rounded: "", type: "is-danger" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("loggers.logger_states.Error")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.companyName.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.companyName.field
              ),
              visible: _vm.tableColumns.companyName.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isCompanyNameSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterCompanyNameDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.filterLoggers(
                                        _vm.tableColumns.companyName.field,
                                        _vm.companyNameSearch
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.companyNameSearch,
                                    callback: function ($$v) {
                                      _vm.companyNameSearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "companyNameSearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterCompanyNameDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.filterLoggers(
                                _vm.tableColumns.companyName.field,
                                _vm.companyNameSearch
                              )
                            },
                          },
                          model: {
                            value: _vm.companyNameSearch,
                            callback: function ($$v) {
                              _vm.companyNameSearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "companyNameSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.currentlyPublishedAs &&
                    loggerList.row.currentlyPublishedAs.company
                      ? [
                          _vm.hasPermissionToViewDepartment(
                            loggerList.row.currentlyPublishedAs.company
                              .companyId
                          )
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "adminCompanyDetail",
                                      params: {
                                        companyId:
                                          loggerList.row.currentlyPublishedAs
                                            .company.companyId,
                                        title: _vm.$t(
                                          "admin.component.loggers.loggerList.title"
                                        ),
                                        lang: _vm.$route.params.lang,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      loggerList.row.currentlyPublishedAs
                                        .company.companyName
                                    )
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    loggerList.row.currentlyPublishedAs.company
                                      .companyName
                                  )
                                ),
                              ]),
                        ]
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.lastSeen.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.lastSeen.field
              ),
              visible: _vm.tableColumns.lastSeen.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dateTimeManager.formatTime(
                            new Date(loggerList.row.lastSeen * 1000),
                            null,
                            null,
                            null
                          )
                        )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.isOnline.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.isOnline.field
              ),
              visible: _vm.tableColumns.isOnline.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isOnlineSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterLoggers(
                                          _vm.tableColumns.isOnline.field,
                                          _vm.onlineSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.onlineSearch,
                                      callback: function ($$v) {
                                        _vm.onlineSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "onlineSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: true } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.last_events.table.has_signal"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "option",
                                      { domProps: { value: false } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "component.last_events.table.no_signal"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterLoggers(
                                      _vm.tableColumns.isOnline.field,
                                      _vm.onlineSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.onlineSearch,
                                  callback: function ($$v) {
                                    _vm.onlineSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "onlineSearch",
                                },
                              },
                              [
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.last_events.table.has_signal"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "component.last_events.table.no_signal"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.additionalProperties.isOnline == "false"
                      ? _c(
                          "b-tag",
                          { attrs: { type: "is-primary", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.last_events.table.no_signal")
                              )
                            ),
                          ]
                        )
                      : loggerList.row.additionalProperties.isOnline == "true"
                      ? _c(
                          "b-tag",
                          { attrs: { type: "is-success", rounded: "" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("component.last_events.table.has_signal")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.state.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.state.field
              ),
              visible: _vm.tableColumns.state.visible,
              sortable: "",
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isStateSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterLoggers(
                                          _vm.tableColumns.state.field,
                                          _vm.stateSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.stateSearch,
                                      callback: function ($$v) {
                                        _vm.stateSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "stateSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          hidden: "",
                                          disabled: "",
                                          selected: "",
                                        },
                                        domProps: { value: null },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("generals.search")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._l(_vm.loggerStates, function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option,
                                          domProps: { value: option },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "admin.component.loggers.state." +
                                                    option.toLowerCase()
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterLoggers(
                                      _vm.tableColumns.state.field,
                                      _vm.stateSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.stateSearch,
                                  callback: function ($$v) {
                                    _vm.stateSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "stateSearch",
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      hidden: "",
                                      disabled: "",
                                      selected: "",
                                    },
                                    domProps: { value: null },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("generals.search")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.loggerStates, function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option,
                                      domProps: { value: option },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.component.loggers.state." +
                                                option.toLowerCase()
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "admin.component.loggers.state." +
                              loggerList.row.state.toLowerCase()
                          )
                        )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.lastStateChange.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.lastStateChange.field
              ),
              visible: _vm.tableColumns.lastStateChange.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.currentlyPublishedAs &&
                    loggerList.row.currentlyPublishedAs.lastStateChange
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dateTimeManager.formatTime(
                                new Date(
                                  loggerList.row.currentlyPublishedAs
                                    .lastStateChange * 1000
                                ),
                                null,
                                null,
                                null
                              )
                            )
                          ),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.battery.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.battery.field
              ),
              visible: _vm.tableColumns.battery.visible,
              numeric: "",
              searchable: "",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isBatterySearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: _vm.$t("generals.search"),
                                    "icon-pack": "fas",
                                    icon: "search",
                                    expanded: "",
                                  },
                                  on: {
                                    input: _vm.handleFilterBatteryDebounce,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.filterLoggers(
                                        _vm.tableColumns.battery.field,
                                        _vm.batterySearch.toString()
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.batterySearch,
                                    callback: function ($$v) {
                                      _vm.batterySearch =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "batterySearch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "number",
                            placeholder: _vm.$t("generals.search"),
                            "icon-pack": "fas",
                            icon: "search",
                            expanded: "",
                          },
                          on: { input: _vm.handleFilterBatteryDebounce },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              _vm.filterLoggers(
                                _vm.tableColumns.battery.field,
                                _vm.batterySearch.toString()
                              )
                            },
                          },
                          model: {
                            value: _vm.batterySearch,
                            callback: function ($$v) {
                              _vm.batterySearch =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "batterySearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.battery && loggerList.row.battery.unit
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(loggerList.row.battery.value) +
                              _vm._s(loggerList.row.battery.unit) +
                              " "
                          ),
                        ])
                      : loggerList.row.rawBattery
                      ? _c("p", [
                          _vm._v(_vm._s(loggerList.row.rawBattery) + "%"),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.calibrated.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.calibrated.field
              ),
              visible: _vm.tableColumns.calibrated.visible,
              searchable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "searchable",
                fn: function () {
                  return [
                    _vm.isCalibrationSearch
                      ? _c(
                          "b-field",
                          {
                            staticClass:
                              "mt-1-mobile is-hidden-tablet is-full-width",
                            attrs: { grouped: "" },
                          },
                          [
                            _c(
                              "b-field",
                              [
                                _c(
                                  "b-select",
                                  {
                                    staticStyle: { width: "80px" },
                                    on: { input: _vm.changeSearchField },
                                    model: {
                                      value: _vm.selectedValue,
                                      callback: function ($$v) {
                                        _vm.selectedValue = $$v
                                      },
                                      expression: "selectedValue",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.tableColumns, function (option) {
                                      return [
                                        option.visible && option.hasSearch
                                          ? _c(
                                              "option",
                                              {
                                                key: option.field,
                                                domProps: {
                                                  value: option.field,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.component.loggers.loggerList.table." +
                                                          option.field
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-field",
                              { attrs: { expanded: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("generals.search"),
                                      "icon-pack": "fas",
                                      icon: "search",
                                      expanded: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.filterLoggers(
                                          _vm.tableColumns.calibrated.field,
                                          _vm.calibrationSearch.toString()
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.calibrationSearch,
                                      callback: function ($$v) {
                                        _vm.calibrationSearch =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "calibrationSearch",
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          value:
                                            _vm.CalibrationStatus.CALIBRATED
                                              .name,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "calibration_status.calibrated"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          value:
                                            _vm.CalibrationStatus.EXPIRED.name,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "calibration_status.expired"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("b-field", [
                              _c(
                                "div",
                                { staticClass: "buttons is-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "times-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-field",
                      {
                        staticClass: "is-hidden-mobile",
                        attrs: { grouped: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("generals.search"),
                                  "icon-pack": "fas",
                                  icon: "search",
                                  expanded: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.filterLoggers(
                                      _vm.tableColumns.calibrated.field,
                                      _vm.calibrationSearch.toString()
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.calibrationSearch,
                                  callback: function ($$v) {
                                    _vm.calibrationSearch =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "calibrationSearch",
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.CalibrationStatus.CALIBRATED.name,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "calibration_status.calibrated"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "option",
                                  {
                                    domProps: {
                                      value: _vm.CalibrationStatus.EXPIRED.name,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("calibration_status.expired")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-flex is-align-items-center ml-3-mobile",
                      },
                      [
                        loggerList.row.currentlyPublishedAs
                          ? _c(
                              "b-tag",
                              {
                                attrs: {
                                  type: loggerList.row.currentlyPublishedAs
                                    .calibration.status.type,
                                  rounded: "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "calibration_status." +
                                        loggerList.row.currentlyPublishedAs
                                          .calibration.status.name
                                    )
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "b-tag",
                              {
                                attrs: {
                                  type: _vm.CalibrationStatus.NOT_CALIBRATED
                                    .type,
                                  rounded: "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "calibration_status." +
                                        _vm.CalibrationStatus.NOT_CALIBRATED
                                          .name
                                    )
                                  )
                                ),
                              ]
                            ),
                        _vm.hasCalibrationProtocolToDownload(
                          loggerList.row.currentlyPublishedAs
                        ) &&
                        _vm.$ability.can(
                          _vm.$permActions.PROTOCOL,
                          _vm.$permSubjects.ADMIN_LOGGERS_CALIBRATION
                        )
                          ? _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.$t("generals.download"),
                                  position: "is-left",
                                  type: "is-light",
                                },
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "control-icon clickable",
                                  attrs: { icon: "file-download" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.downloadCalibration(
                                        loggerList.row.currentlyPublishedAs
                                          .companyLoggerId
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.calibrationExpiresAt.field,
              label: _vm.$t(
                "admin.component.loggers.loggerList.table." +
                  _vm.tableColumns.calibrationExpiresAt.field
              ),
              visible: _vm.tableColumns.calibrationExpiresAt.visible,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    loggerList.row.currentlyPublishedAs
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dateTimeManager.formatTime(
                                  new Date(
                                    loggerList.row.currentlyPublishedAs
                                      .calibration.expiresAt * 1000
                                  ),
                                  null,
                                  null,
                                  null
                                )
                              ) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "action",
              label: _vm.$t("admin.component.loggers.loggerList.table.action"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (loggerList) {
                  return [
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "append-to-body": "",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function () {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        "icon-pack": "fa",
                                        "icon-right": "angle-down",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.component.loggers.detail.actions.title"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "aria-role": "menu-item",
                              focusable: false,
                              custom: "",
                            },
                          },
                          [
                            _c("span", { staticClass: "has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.loggers.detail.actions.technical"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              "aria-role": "listitem",
                              disabled:
                                !_vm.canPublish(loggerList.row) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.PUBLISH,
                                  _vm.$permSubjects.ADMIN_LOGGERS
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.publishClicked(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", { attrs: { icon: "heading" } }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.loggers.detail.actions.publish"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "level-right" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "admin.component.loggers.detail.actions.publishNote"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              "aria-role": "listitem",
                              disabled:
                                !_vm.canUnpublish(loggerList.row) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.UNPUBLISH,
                                  _vm.$permSubjects.ADMIN_LOGGERS
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.unpublishClicked(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "remove-format" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.loggers.detail.actions.unpublish"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "level-right" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "admin.component.loggers.detail.actions.unpublishNote"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              "aria-role": "listitem",
                              disabled:
                                !_vm.canReadData(loggerList.row) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.READDATA,
                                  _vm.$permSubjects.ADMIN_LOGGERS_COMMANDS
                                ) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.READ,
                                  _vm.$permSubjects.ADMIN_GATEWAYS
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openReadDataModal(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("b-icon", { attrs: { icon: "book-open" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "admin.component.loggers.detail.actions.readData"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.addVerificationKey(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", { attrs: { icon: "key" } }),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "admin.component.loggers.detail.actions.addVerificationKey"
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: { "aria-role": "listitem" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteVerificationKey(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", { attrs: { icon: "trash" } }),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "admin.component.loggers.detail.actions.deleteVerificationKey"
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "aria-role": "menu-item",
                              focusable: false,
                              custom: "",
                            },
                          },
                          [
                            _c("span", { staticClass: "has-text-grey" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "admin.component.loggers.detail.actions.sales"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              "aria-role": "listitem",
                              disabled:
                                !_vm.canActivate(loggerList.row) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.ACTIVATE,
                                  _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.activateClicked(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left mr-1" },
                                [
                                  _c("b-icon", { attrs: { icon: "plus" } }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "admin.component.loggers.detail.actions.activate"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "level-right" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "admin.component.loggers.detail.actions.activateNote"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              "aria-role": "listitem",
                              disabled:
                                !_vm.canDeactivate(loggerList.row) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.DEACTIVATE,
                                  _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deactivateClicked(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", { attrs: { icon: "minus" } }),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "admin.component.loggers.detail.actions.deactivate"
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "level-right" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "admin.component.loggers.detail.actions.deactivateNote"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              "aria-role": "listitem",
                              disabled:
                                !_vm.canRevert(loggerList.row) ||
                                !_vm.$ability.can(
                                  _vm.$permActions.REVERT,
                                  _vm.$permSubjects.ADMIN_COMPANYLOGGERS
                                ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.revertDevice(loggerList.row)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "level is-mobile" }, [
                              _c(
                                "div",
                                { staticClass: "level-left" },
                                [
                                  _c("b-icon", { attrs: { icon: "redo-alt" } }),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "admin.component.loggers.detail.actions.revert"
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "level-right" },
                                [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        type: "is-dark",
                                        label: _vm.$t(
                                          "admin.component.loggers.detail.actions.revertNote"
                                        ),
                                        position: "is-left",
                                        multilined: "",
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          size: "is-small",
                                          icon: "question-circle",
                                          type: "is-grey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("admin.component.loggers.loggerList.table.empty")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isSerialNumberModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isSerialNumberModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("PublishLoggerModal", {
            attrs: { logger: _vm.selectedLogger },
            on: {
              modalClosed: _vm.closeModal,
              serialNumberSelected: _vm.serialNumberSelected,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isCompanyModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isCompanyModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("SelectCompanyModal", {
            attrs: { logger: _vm.selectedLogger },
            on: {
              modalClosed: _vm.closeModal,
              companySelected: _vm.companySelected,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isReadDataModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isReadDataModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("ReadDataFromLoggerModal", {
            attrs: { logger: _vm.selectedLogger },
            on: { modalClosed: _vm.closeModal, readData: _vm.readData },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isVerificationKeyModalActive,
            "has-modal-card": "",
            onCancel: _vm.closeModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.isVerificationKeyModalActive = $event
            },
            "close-modal": _vm.closeModal,
          },
        },
        [
          _c("CreateVerificationKeyModal", {
            on: {
              modalClosed: _vm.closeModal,
              createVerificationKey: _vm.createVerificationKey,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }