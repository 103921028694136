import AxiosService from '@/services/api/AxiosService';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { Department } from '@/entities/models/Department';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiResponse from '@/entities/ApiResponse';
import { DepartmentSettings } from '@/entities/models/DepartmentSettings';
import { AdminDepartment } from '@/entities/models/AdminDepartment';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import { AdminDepartmentSettings } from '@/entities/AdminDepartmentSettings';
import { vxm } from '@/store/store.vuex';
import { Invitation } from '@/entities/models/Invitation';
import LoggedUserManager from '../LoggedUserManager';
import { FloorPlan } from '@/entities/models/FloorPlan';
import { FloorPlanLocationData } from '@/entities/models/FloorplanLocationData';
import { DepartmentIssueSummary } from '@/entities/models/DepartmentIssue';
var vxDepartmentStore = vxm.departmentStore;
export default class DepartmentRepository {
    constructor(ctx) {
        this.ctx = ctx;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this.ctx);
    }
    /**
     * Gets all departments for current user
     * @param [pagination]
     * @param [sort]
     * @returns DepartmentArray
     */
    async getDepartments(pagination = null, sort = null) {
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let url = ApiLinks.Department.Home;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        let departmentsResponse = new ApiResponse();
        if (response.status != ApiStatus.SUCCESS)
            return departmentsResponse;
        else {
            //Create response with pagination data
            departmentsResponse.setPagination(response.data);
            departmentsResponse.setData(Department.fromApi(response.data.data));
            return departmentsResponse;
        }
    }
    /**
     * Gets department by ID. If no ID is specified, currently selected department ID is used.
     * @param [departmentId]
     * @returns department
     */
    async getDepartment(departmentId = this.getSelectedDepartmentId()) {
        if (!departmentId)
            return null;
        let url = `${ApiLinks.Department.Home}/${departmentId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Department();
        else
            return Department.fromApi(response.data.data);
    }
    async getDepartmentIssues() {
        let url = `${ApiLinks.Department.Home}${ApiLinks.Department.Keys.Issues}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status !== ApiStatus.SUCCESS)
            return [];
        else
            return DepartmentIssueSummary.fromApi(response.data.data);
    }
    /**
     * Updates department
     * @param data
     * @param [departmentId]
     * @returns department
     */
    async updateDepartment(data, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY)) {
            throw 'Missing permissions for action updateDepartment';
        }
        let url = `${ApiLinks.Department.Home}/${departmentId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new Department();
        else
            return Department.fromApi(response.data.data);
    }
    /**
     * Gets department settings for given department ID
     * @param [departmentId]
     * @returns department settings
     */
    async getDepartmentSettings(departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return DepartmentSettings.fromApi(response.data.data);
    }
    /**
     * Saves department setting
     * @param key Array<string>
     * @param value string | number | boolean
     * @param [departmentId]
     * @returns department setting object
     */
    async saveDepartmentSetting(key, value, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY)) {
            throw 'Missing permissions for action saveDepartmentSetting';
        }
        let path = key.join('.');
        let userSetting = [{ Key: path, Value: value }];
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Settings}`;
        let response = await AxiosService.postDataToServer(url, userSetting, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else {
            let settings = DepartmentSettings.fromApi(response.data.data);
            return settings;
        }
    }
    async saveDepartmentPdfSetting(key, value, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY)) {
            throw 'Missing permissions for action saveDepartmentPdfSetting';
        }
        let path = key.join('.');
        let userSetting = [{ Key: path, Value: value }];
        //let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Settings}/${path}`;
        //let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Settings}`;
        let response = await AxiosService.postDataToServer(url, userSetting, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    /**
     * Invites users to department with given id
     * @param config list of email addresses + roles to send invitation link
     * @param [departmentId]
     * @returns boolean or response code
     */
    async inviteUsers(config, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.REGISTER, Subjects.USER) && !this.isResellerAdmin) {
            throw 'Missing permissions for action inviteUsers';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Users}${ApiLinks.Department.Keys.Invite}`;
        let response = await AxiosService.postDataToServer(url, config, this.ctx, false);
        if (response.status === ApiStatus.NOT_ALLOWED) {
            return response.data.code;
        }
        else
            return response.status == ApiStatus.SUCCESS;
    }
    /**
     * Gets all departments in system sorted by name (Admin call)
     * @returns List of AdminDepartment objects
     */
    async getAllDepartmentsAdmin(ignorePermissions = false, pagination = null, sort = null) {
        if (ignorePermissions == false && !this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS)) {
            return new Array();
        }
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        let url = ApiLinks.Department.AdminHome;
        //If there are some queries defined, add them to url
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return AdminDepartment.sortByName(AdminDepartment.fromApi(response.data.data));
        }
    }
    /**
     * Gets department by ID (Admin call)
     * @param [departmentId]
     * @returns AdminDepartment
     */
    async getDepartmentAdmin(departmentId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS)) {
            return new AdminDepartment();
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminDepartment();
        else
            return AdminDepartment.fromApi(response.data.data);
    }
    /**
     * Gets admin department settings for given department ID
     * @param departmentId string
     * @returns department settings
     */
    async getAdminDepartmentSettings(departmentId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_SETTINGS)) {
            throw 'Missing permissions for action getAdminDepartmentSettings';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return AdminDepartmentSettings.fromApi(response.data.data);
    }
    /**
     * Deletes admin department settings in given department
     * @param departmentId string
     * @param key string
     * @returns department settings
     */
    async deleteAdminDepartmentSettings(departmentId, key) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_DEPARTMENTS_SETTINGS)) {
            throw 'Missing permissions for action deleteAdminDepartmentSettings';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Settings}/${key}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.NO_CONTENT)
            return false;
        else
            return true;
    }
    /**
     * Delete department settings in given department
     * @param key array string
     * @param [departmentId]
     * @returns boolean or response code
     */
    async deleteDepartmentSettings(key, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY)) {
            throw 'Missing permissions for action deleteDepartmentSettings';
        }
        let keyString = key.join('.');
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Settings}/${keyString}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return true;
    }
    /**
     * Creates or update admin department settings for given department
     * @param departmentId string
     * @param dto IAdminDepartmentSettingsDTO
     * @returns department settings
     */
    async createOrUpdateAdminDepartmentSettings(departmentId, dto) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_DEPARTMENTS_SETTINGS)) {
            throw 'Missing permissions for action createOrUpdateAdminDepartmentSettings';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Settings}`;
        let response = await AxiosService.postDataToServer(url, [dto], this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return AdminDepartmentSettings.fromApi(response.data.data);
    }
    /**
     * Gets all departments in system for given userId sorted by name (Admin call)
     * @returns List of AdminDepartment objects
     */
    async getAllDepartmentsForUserAdmin(userId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_USERS_DEPARTMENTS)) {
            return new Array();
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Departments}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return response.data.data;
        }
    }
    /**
     * Create new empty Department
     */
    async adminCreateDepartment(data) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_DEPARTMENTS)) {
            throw 'Missing permissions for action adminCreateDepartment';
        }
        let url = ApiLinks.Department.AdminHome;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.CREATED)
            return response.data.data;
        else
            return null;
    }
    /**
     * Update Department name
     */
    async adminUpdateDepartment(departmentId, data) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_DEPARTMENTS)) {
            throw 'Missing permissions for action adminUpdateDepartment';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminDepartment();
        else
            return AdminDepartment.fromApi(response.data.data);
    }
    /**
     * Delete Department
     */
    async deleteAdminDepartment(departmentId) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_DEPARTMENTS)) {
            return null;
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    /**
     * Gets all department issues when logged from admin
     * @returns department issues summary for admin
     */
    async getAllDepartmentIssuesAdmin() {
        let url = `${ApiLinks.Department.AdminHome}${ApiLinks.Department.Keys.Issues}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status !== ApiStatus.SUCCESS)
            return [];
        else
            return DepartmentIssueSummary.fromApi(response.data.data);
    }
    async getSentInvitations(departmentId = this.getSelectedDepartmentId(), pagination = null, sort = null) {
        let queries = new ApiQueries();
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Users}${ApiLinks.Department.Keys.Invite}`;
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return Invitation.fromApi(response.data.data);
        }
    }
    async cancelSentInvitation(invitationId, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Users}${ApiLinks.Department.Keys.Invite}/${invitationId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    async getFloorPlans(departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return FloorPlan.fromApi(response.data.data).sort((a, b) => (a.name > b.name ? 1 : -1));
        }
    }
    async getFloorPlanData(floorPlanId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}/${floorPlanId}${ApiLinks.Sources.Keys.Data}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return FloorPlanLocationData.fromApi(response.data.data);
        }
    }
    async getFloorPlanImage(floorPlanId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}/${floorPlanId}${ApiLinks.Sources.Keys.Image}`;
        let response = await AxiosService.downloadFile_GET(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return response;
    }
    async getFloorPlanThumbnail(floorPlanId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}/${floorPlanId}${ApiLinks.Sources.Keys.Thumbnail}`;
        let response = await AxiosService.downloadFile_GET(url, this.ctx, false);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return response;
    }
    async uploadFloorPlan(name, image, thumbnail, positions, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}`;
        let data = new FormData();
        data.append('image', image);
        data.append('thumbnail', thumbnail);
        data.append('name', name);
        data.append('data', JSON.stringify(positions));
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return response;
    }
    async updateFloorPlanData(floorPlanId, positions, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}/${floorPlanId}${ApiLinks.Sources.Keys.Data}`;
        let data = new FormData();
        data.append('data', JSON.stringify(positions));
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return [];
        else
            return FloorPlan.fromApi(response.data.data);
    }
    async updateFloorPlan(floorPlanId, name, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}/${floorPlanId}`;
        let data = new FormData();
        data.append('name', name);
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return [];
        else
            return FloorPlan.fromApi(response.data.data);
    }
    async deleteFloorPlan(floorplanId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}/${floorplanId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    async deleteThermoMap(departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    async uploadThermoMap(image, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Home}/${departmentId}${ApiLinks.Sources.Keys.FloorPlan}`;
        let data = new FormData();
        data.append('image', image);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return response;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
