var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.reportPdfConfiguration
        ? _c(
            "b-notification",
            {
              attrs: {
                type: "is-warning",
                "has-icon": "",
                "aria-close-label": "Close notification",
                role: "alert",
                closable: false,
              },
            },
            [
              _c("div", { staticClass: "multi-header" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("reports.report_settings.empty_settings")) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _vm.$ability.can(
                      _vm.$permActions.MANAGEOWN,
                      _vm.$permSubjects.COMPANY
                    )
                      ? _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.openReportSettingModal()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "reports.report_settings.config_report"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.reportPdfConfiguration
        ? _c("div", { staticClass: "multi-header has-bottom-margin" }, [
            _c("h2", { staticClass: "subtitle is-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("reports.report_settings.reports_setting")) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.$ability.can(
                  _vm.$permActions.MANAGEOWN,
                  _vm.$permSubjects.COMPANY
                )
                  ? _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openReportSettingModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("reports.report_settings.config_report")
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$t(
                        "reports.report_settings.deleteReportConfigModal.title"
                      ),
                      position: "is-left",
                      type: "is-dark",
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.deleteConfigModal()
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: { type: "is-primary", icon: "trash" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.reportPdfConfiguration
        ? _c("section", [
            _c("div", { staticClass: "level section-header-level" }, [
              _c(
                "table",
                { staticClass: "info-table mobile-filter card-table" },
                [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("reports.report_settings.report_freq"))
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.periodicReportPdfType
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "reports.report_settings.report_config_modal.configuration.frequent_range." +
                                      _vm.reportPdfConfiguration
                                        .periodicReportPdfType
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("reports.report_settings.report_loggers")
                          )
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.sourceIds
                        ? _c(
                            "span",
                            _vm._l(_vm.sourcesbyGroup, function (Source) {
                              return _c("ul", { key: Source.id }, [
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "logger_detail",
                                            params: {
                                              locationId: Source.id,
                                              departmentId:
                                                _vm.$route.params.departmentId,
                                              lang: _vm.$route.params.lang,
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(Source.name) + " ")]
                                    ),
                                    Source.sourceGroup
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(Source.sourceGroup.name) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          )
                        : _c("span", { staticClass: "has-text-grey-light" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("reports.report_settings.empty")
                                ) +
                                " "
                            ),
                          ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("reports.report_settings.records"))
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.showRecordsTable ||
                      _vm.reportPdfConfiguration.showRecordsTable === false
                        ? _c(
                            "span",
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.reportPdfConfiguration
                                    .showRecordsTable
                                    ? "check"
                                    : "times",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("-")]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "table",
                { staticClass: "info-table mobile-filter card-table" },
                [
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("reports.report_settings.interval"))
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.interval
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.reportPdfConfiguration.interval > 30
                                    ? _vm.$tc(
                                        "time.hour",
                                        _vm.reportPdfConfiguration.interval / 60
                                      )
                                    : _vm.$tc(
                                        "time.minute",
                                        _vm.reportPdfConfiguration.interval
                                      )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                      _vm.reportPdfConfiguration.statModels
                        ? _c("span", [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.$t(
                                    "component.report.statisticTypes." +
                                      _vm.formatStatModel()
                                  )
                                ) +
                                ") "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.formatStatModel() === "daily"
                    ? _c("tr", [
                        _c("td", { staticClass: "row-label" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("reports.report_settings.measuredAt")
                              )
                            ),
                          ]),
                        ]),
                        _vm.reportPdfConfiguration.offset >= 0
                          ? _c("td", [
                              _vm._v(
                                " " + _vm._s(_vm.formatMeasureTime()) + " "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("reports.report_settings.eventTypes"))
                        ),
                      ]),
                    ]),
                    _vm.reportPdfConfiguration.eventTypes.length > 0
                      ? _c("td", [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.currentTypes) + " "),
                          ]),
                        ])
                      : _c("td", [_vm._v("-")]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("reports.report_settings.chart"))),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.showChart ||
                      _vm.reportPdfConfiguration.showChart === false
                        ? _c(
                            "span",
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.reportPdfConfiguration.showChart
                                    ? "check"
                                    : "times",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("-")]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("reports.report_settings.empty_records")
                          )
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.showEmpty ||
                      _vm.reportPdfConfiguration.showEmpty === false
                        ? _c(
                            "span",
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.reportPdfConfiguration.showEmpty
                                    ? "check"
                                    : "times",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("-")]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "row-label" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("reports.report_settings.annotations"))
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm.reportPdfConfiguration.showAnnotationTable ||
                      _vm.reportPdfConfiguration.showAnnotationTable === false
                        ? _c(
                            "span",
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.reportPdfConfiguration
                                    .showAnnotationTable
                                    ? "check"
                                    : "times",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("-")]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }